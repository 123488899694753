<template>
    <content-view>
        <div class="test-fail">
            <div class="test-fail__wrapper">
                <div class="test-fail__icon"></div>
                <h1 class="test-fail__title">Тест не пройден</h1>
                <div class="test-fail__buttons">
                    <router-link
                        tag="button"
                        :to="`/courses/${routeParams.course_id}/test/${routeParams.test_id}`"
                        class="v-btn"
                    >
                        <svg class="test-fail__btn-icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.66943 13.9607C10.4778 13.6461 12.7285 11.4011 13.0488 8.59276C13.4692 4.92069 10.6151 1.79486 7.0374 1.75483V0.107546C7.0374 0.0160301 6.92301 -0.0325876 6.84579 0.0246097L3.45399 2.51555C3.39679 2.55845 3.39679 2.64138 3.45399 2.68428L6.84579 5.17522C6.92301 5.23242 7.0374 5.18094 7.0374 5.09229V3.44787C9.55123 3.4879 11.5646 5.62708 11.393 8.1838C11.2471 10.383 9.45399 12.1676 7.25475 12.3077C4.92396 12.4564 2.94207 10.792 2.58745 8.5899C2.52167 8.18094 2.16419 7.88351 1.75237 7.88351C1.24045 7.88351 0.84007 8.33823 0.920146 8.84443C1.41776 11.9817 4.31481 14.3354 7.66943 13.9607Z" fill="black"/>
                        </svg>
                        Попробовать ещё раз
                    </router-link>
                    <router-link
                        tag="button"
                        :to="`/courses/${routeParams.course_id}`"
                        class="v-btn v-btn--gold-bordered mt-15"
                    >
                        <svg class="test-fail__btn-icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 3L0 6.82609L6 11V3Z" fill="black"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 7C2 6.44771 2.48842 6 3.09091 6L12.9091 6C13.5116 6 14 6.44772 14 7C14 7.55229 13.5116 8 12.9091 8L3.09091 8C2.48842 8 2 7.55228 2 7Z" fill="black"/>
                        </svg>
                        Вернуться к урокам
                    </router-link>
                </div>
            </div>
        </div>
    </content-view>
</template>

<script>
export default {
name: "TestFail",
    components: {
        ContentView : () => import('@/views/menu/ContentView')
    },
    data () {
        return {
            routeParams: this.$route.params
        }
    },
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
.test-fail {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 120px 0 80px;

    color: $black;
    background-color: $white;

    @include below($tablet) {
        width: auto;
        padding: 80px 0;
    }

    @include below($mobile) {
        padding: 40px 0;
        margin-bottom: 20px;
    }

    &__wrapper {
        max-width: rem(705);
        text-align: center;
    }

    &__icon {
        margin: 0 auto rem(47);
        width: rem(345);
        height: rem(245);
        background-image: url("~assets/img/course/test-fail.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @include below($mobile) {
            width: 80%;
            margin-bottom: rem(30);

        }
    }

    &__title {
        margin-bottom: rem(55);

        font-size: rem(40);
        line-height: 1.3;

        @include below($mobile) {
            margin: 0 auto 20px;

            font-size: rem(22);
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        button {
            width: 270px;
        }
    }

    &__btn-icon {
        margin-right: 10px;
    }

}
</style>
